import react from "react";

const Projects = () => {
  return (
    // using style from home as placeholder for now
    <div className="home-content">
      <div className="home-content-container">
        <div className="home-content-text-area">
          <h1 className="title">Coming soon...</h1>
        </div>
      </div>
    </div>
  );
};

export default Projects;
